
import { defineComponent } from "vue";
import { convert } from "renpy-converter";
import one from "./json-examples/1-plain-text-dialog-single-line";
import two from "./json-examples/2-plain-text-multiple-line";
import three from "./json-examples/3-with-refs";
import four from "./json-examples/4-with-refs-explicit-names";
import five from "./json-examples/5-branching-dialog";

export default defineComponent({
  name: "Renpy Converter",
  data() {
    return {
      selected: {},
      jsons: [
        {
          name: "1-plain-text-dialog-single-line",
          jsonValue: one,
        },
        {
          name: "2-plain-text-multiple-line",
          jsonValue: two,
        },
        {
          name: "3-with-refs",
          jsonValue: three,
        },
        {
          name: "4-with-refs-explicit-names",
          jsonValue: four,
        },
        {
          name: "5-branching-dialog",
          jsonValue: five,
        },
      ],
    };
  },
  methods: {
    convert(data: unknown) {
      if (data == null) return;
      return convert(data);
    },
  },
});
